import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Anchorlink from '../components/Anchorlink';

const TagsSection = styled.section`
  width: 100%;
  display: flex;
  padding: 50px;
`;

const StyledContainer = styled.div`
  display: flex;
  padding: 40px;
`;

const StyledTag = styled.div`
  margin-bottom: 3rem;
`;

const TagsTitle = styled.h3`
  font-size: ${({ theme }) => theme.font.size.s};
  margin: 0 0 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.five};
  position: relative;
`;

const TagsList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: outside;
  margin-left: 30px;
`;

const ListItem = styled.li`
  margin-right: 15px;
`;

const TagRoute = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges;
  const postLinks = posts.map((post) => (
    <ListItem key={post.node.fields.slug}>
      <Anchorlink ariaLabel="tags" to={post.node.fields.slug}>
        <TagsTitle>{post.node.frontmatter.title}</TagsTitle>
      </Anchorlink>
    </ListItem>
  ));
  const { tag } = pageContext;
  const { title } = data.site.siteMetadata;
  const { totalCount } = data.allMarkdownRemark;
  const endOfWord = () => {
    if (totalCount === 1) {
      return '';
    }
    if (totalCount < 5) {
      return 'y';
    }
    if (totalCount >= 5) {
      return 'ów';
    }
  };
  const tagHeader = `${totalCount} post${endOfWord()} otagowano frazą “${tag}”`;

  return (
    <Layout>
      <TagsSection>
        <Helmet title={`${tag} | ${title}`} />
        <StyledContainer>
          <StyledTag>
            <TagsTitle>{tagHeader}</TagsTitle>
            <TagsList>{postLinks}</TagsList>
            <Anchorlink to="/tags/">Pokaż wszystkie tagi</Anchorlink>
          </StyledTag>
        </StyledContainer>
      </TagsSection>
    </Layout>
  );
};

TagRoute.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
};

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
